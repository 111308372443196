import SweetScroll from 'sweet-scroll';
import { register as registerScrollListener } from './scroll-listener';

/*
 * Scroll down in steps
 */
const SCROLL_LISTENER_ID = "scrollDownScrollListener";
const SHOW_BUTTON_TIMEOUT = 1000;
let viewportHeight;

const scrollHandler = (el) => (e) => {
  if (window.pageYOffset < viewportHeight) {
    el.classList.remove('is-hidden');
  } else {
    el.classList.add('is-hidden');
  }
};

export const handler = (el, e) => {
  e.preventDefault();
  const newOffset = window.pageYOffset + 500;
  const sweetScroll = new SweetScroll({
    duration: 340,
    easing: 'easeInOutQuad',
  });
  sweetScroll.to(newOffset);
};

export const enhancer = (el, e) => {
  // show button after timeout
  setTimeout(() => {
    el.classList.remove('is-hidden');
  }, SHOW_BUTTON_TIMEOUT);
  // scroll
  viewportHeight = window.innerHeight;
  registerScrollListener(SCROLL_LISTENER_ID, scrollHandler(el));
};
